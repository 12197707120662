import './styles.css';

import {ReactComponent as FaviconSvg} from '../header/assets/favicon-white.svg'
import {useTranslation} from "../localization/translations";

const Footer = () => {
  const translation = useTranslation();
  return (
    <>
      <div className="footer-container">
        <a className="footer-favicon-link" href="/">
          <FaviconSvg className="footer-favicon"/>
        </a>
        <div
          className="footer-link"
          onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
        >{translation.BACK_TO_TOP}
        </div>
        <div className="footer-copyright">© 2024 Daria Kutluieva</div>
      </div>
    </>
  )
};

export default Footer;
