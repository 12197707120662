import React, { useEffect, useRef } from "react";
import Matrix2D from "./utils/Matrix2D";
import { DARIA_SEGMENTS, LETTER_WIDTH } from "./animated_text/alphabet";
import SkillIcons from "./SkillIcons";
import AwsCertifiedImage from "./assets/aws-certified.png";
import AvatarImage from "./assets/avatar.png";
import { ReactComponent as GithubIcon } from "../side_projects/assets/github2.svg";
import { ReactComponent as LinkedInIcon } from "./assets/linkedin.svg";
import { motion } from "framer-motion";
import { useTranslation } from "../localization/translations";

const CANVAS_HEIGHT = 500;

const LINKEDIN_LINK = "https://www.linkedin.com/in/kutluieva/";
const GITHUB_PORTFOLIO_LINK =
  "https://github.com/daryakut/";

class SegmentedLine {
  controlPoint1X = null;
  controlPoint1Y = null;
  controlPoint2X = null;
  controlPoint2Y = null;
  previousPoint1X = null;
  previousPoint1Y = null;
  previousPoint2X = null;
  previousPoint2Y = null;
  width = null;
  vectorMatrix = null;
  transformMatrix = null;

  lifespan = null;
  segments = [];
  allSegmentsDuration = null;
}

class CanvasLetterWriter {
  canvasElement;
  context;

  // Width and height of the canvas in its dimensions, can be different than screen dimensions due to dpi and scale
  canvasWidth;
  canvasHeight;

  // Where we start drawing the letters and the interval
  drawStartPosition;
  animationFrameId = null;

  // The current letter being drawn and the interval to add letters
  currentLetterId = 0;
  addLetterInterval = null;

  // Segments already added to be drawn
  segmentedLines = [];

  constructor(canvasElement) {
    this.canvasElement = canvasElement;
  }

  initializeCanvas = () => {
    // Dpi is how many actual screen pixels are in a single canvas pixel (e.g. on retina screens can be 2 or 3)
    const dpi = window.devicePixelRatio || 1;

    // Make sure canvas is big enough for a retina display to have nice clean pixels
    this.canvasElement.width = window.innerWidth * dpi;
    this.canvasElement.height = CANVAS_HEIGHT * dpi;

    this.context = this.canvasElement.getContext("2d");
    this.context.fillStyle = "#000000";
    this.context.strokeStyle = "#000000";

    // Scale determines how much the canvas is zoomed down. Scale less than 2 will have letters too big
    const scale = Math.max(
      DARIA_SEGMENTS.totalWidth / (window.innerWidth * 0.6),
      2
    );

    this.canvasWidth = window.innerWidth * scale;
    this.canvasHeight = CANVAS_HEIGHT * scale;
    this.context.scale(dpi / scale, dpi / scale);

    // Draw at the screen center
    this.drawStartPosition = {
      x: this.canvasWidth / 2 - DARIA_SEGMENTS.totalWidth / 2,
      y: this.canvasHeight / 2 + DARIA_SEGMENTS.totalHeight / 2,
    };

    // Start adding letters to list of segments
    this.createSegmentedLinesForNextLetter();
    this.addLetterInterval = setInterval(
      this.createSegmentedLinesForNextLetter,
      150
    );

    // Draw segments already added to the list
    this.startAnimation();
  };

  startAnimation = () => {
    // This is the main animation loop, similar to setInterval but more efficient
    const animate = () => {
      this.drawLetters();
      this.animationFrameId = requestAnimationFrame(animate);
    };
    this.animationFrameId = requestAnimationFrame(animate);
  };

  stopAnimation = () => {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  };

  clearCanvas = () => {
    // This helps us re-draw the canvas in case of a screen resize
    this.stopAnimation();
    clearInterval(this.addLetterInterval);
    this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.currentLetterId = 0;
    this.segmentedLines = [];
  };

  handleWindowResize = (e) => {
    this.clearCanvas();
    this.initializeCanvas();
  };

  createSegmentedLinesForNextLetter = () => {
    if (this.currentLetterId >= DARIA_SEGMENTS.segments.length) {
      // Finished drawing all letters, stop adding new ones
      clearInterval(this.addLetterInterval);
      return;
    }

    const letter = DARIA_SEGMENTS.segments[this.currentLetterId++];
    const lines = letter.lines;

    for (let line of lines) {
      let movementMatrix = new Matrix2D();
      movementMatrix.rotate(-Math.PI / 2);
      movementMatrix.translate(
        this.drawStartPosition.x,
        this.drawStartPosition.y
      );
      movementMatrix.translate(letter.displacement.x, letter.displacement.y);

      let transformMatrix = new Matrix2D();

      let segmentedLine = new SegmentedLine();
      segmentedLine.controlPoint1X =
        -LETTER_WIDTH * movementMatrix.c + movementMatrix.tx;
      segmentedLine.previousPoint1X =
        -LETTER_WIDTH * movementMatrix.c + movementMatrix.tx;

      segmentedLine.controlPoint1Y =
        -LETTER_WIDTH * movementMatrix.d + movementMatrix.ty;
      segmentedLine.previousPoint1Y =
        -LETTER_WIDTH * movementMatrix.d + movementMatrix.ty;

      segmentedLine.controlPoint2X =
        LETTER_WIDTH * movementMatrix.c + movementMatrix.tx;
      segmentedLine.previousPoint2X =
        LETTER_WIDTH * movementMatrix.c + movementMatrix.tx;

      segmentedLine.controlPoint2Y =
        LETTER_WIDTH * movementMatrix.d + movementMatrix.ty;
      segmentedLine.previousPoint2Y =
        LETTER_WIDTH * movementMatrix.d + movementMatrix.ty;

      segmentedLine.vectorMatrix = movementMatrix;
      segmentedLine.transformMatrix = transformMatrix;

      segmentedLine.lifespan = 0;

      segmentedLine.segments = line;
      segmentedLine.allSegmentsDuration = segmentedLine.segments.reduce(
        (acc, segment) => acc + segment.durationFrames,
        0
      );

      this.segmentedLines.push(segmentedLine);
    }
  };

  drawLetters = () => {
    for (let i = 0; i < this.segmentedLines.length; i++) {
      let segmentedLine = this.segmentedLines[i];

      if (segmentedLine.lifespan < segmentedLine.allSegmentsDuration) {
        this.drawLineSegment(segmentedLine);
        segmentedLine.lifespan++;
      } else {
        this.segmentedLines.splice(i, 1);
        i--;
      }
    }
  };

  drawLineSegment = (segmentedLine) => {
    let lifespanMinusSegmentDurations = segmentedLine.lifespan;
    let currentSegment = null;
    let startedNewSegment = false;
    for (let i = 0; i < segmentedLine.segments.length; i++) {
      const segment = segmentedLine.segments[i];
      if (lifespanMinusSegmentDurations >= segment.durationFrames) {
        lifespanMinusSegmentDurations -= segment.durationFrames;
        continue;
      }
      currentSegment = segment;
      if (i !== segmentedLine.currentSegmentId) {
        startedNewSegment = true;
        segmentedLine.currentSegmentId = i;
      }
      break;
    }
    if (!currentSegment) {
      console.error(
        "No current segment found, stopping animation",
        segmentedLine
      );
      return;
    }

    const transformMatrix = new Matrix2D();
    if (startedNewSegment) {
      // Apply rotation to the current matrix
      if (currentSegment.rotationAtStart) {
        // transformMatrix.rotate(currentSegment.rotationAtStart);
        segmentedLine.vectorMatrix.rotate(currentSegment.rotationAtStart);
      }

      // Reset width to the start value
      if (currentSegment.widthAtStart) {
        segmentedLine.width = currentSegment.widthAtStart;
      }
    }

    // Update movement every frame
    if (currentSegment.movementPerFrame) {
      // transformMatrix.translate(currentSegment.movementPerFrame, 0);
      transformMatrix.translate(currentSegment.movementPerFrame, 0);
    }

    // Update scale every frame
    if (currentSegment.scalePerFrame) {
      // transformMatrix.scale(currentSegment.scalePerFrame, currentSegment.scalePerFrame);
      transformMatrix.scale(
        currentSegment.scalePerFrame,
        currentSegment.scalePerFrame
      );
    }

    // Update width every frame, not just at the start of the segment
    if (currentSegment.widthMultiplierPerFrame) {
      segmentedLine.width *= currentSegment.widthMultiplierPerFrame;
    }

    // Update rotation every frame
    if (currentSegment.rotationPerFrame) {
      transformMatrix.rotate(currentSegment.rotationPerFrame);
    }

    segmentedLine.vectorMatrix.prependMatrix(transformMatrix);

    const width = segmentedLine.width;
    let controlPoint1X =
      -width * segmentedLine.vectorMatrix.c + segmentedLine.vectorMatrix.tx;
    let controlPoint1Y =
      -width * segmentedLine.vectorMatrix.d + segmentedLine.vectorMatrix.ty;

    let midPoint1X = (segmentedLine.controlPoint1X + controlPoint1X) / 2;
    let midPoint1Y = (segmentedLine.controlPoint1Y + controlPoint1Y) / 2;

    let controlPoint2X =
      width * segmentedLine.vectorMatrix.c + segmentedLine.vectorMatrix.tx;
    let controlPoint2Y =
      width * segmentedLine.vectorMatrix.d + segmentedLine.vectorMatrix.ty;

    let midPoint2X = (segmentedLine.controlPoint2X + controlPoint2X) / 2;
    let midPoint2Y = (segmentedLine.controlPoint2Y + controlPoint2Y) / 2;

    this.context.beginPath();

    this.context.moveTo(
      segmentedLine.previousPoint1X,
      segmentedLine.previousPoint1Y
    );
    this.context.quadraticCurveTo(
      segmentedLine.controlPoint1X,
      segmentedLine.controlPoint1Y,
      midPoint1X,
      midPoint1Y
    );

    this.context.lineTo(midPoint2X, midPoint2Y);

    this.context.quadraticCurveTo(
      segmentedLine.controlPoint2X,
      segmentedLine.controlPoint2Y,
      segmentedLine.previousPoint2X,
      segmentedLine.previousPoint2Y
    );

    this.context.closePath();
    this.context.fill();

    // Update segment points
    segmentedLine.previousPoint1X = midPoint1X;
    segmentedLine.previousPoint1Y = midPoint1Y;
    segmentedLine.previousPoint2X = midPoint2X;
    segmentedLine.previousPoint2Y = midPoint2Y;

    segmentedLine.controlPoint1X = controlPoint1X;
    segmentedLine.controlPoint1Y = controlPoint1Y;
    segmentedLine.controlPoint2X = controlPoint2X;
    segmentedLine.controlPoint2Y = controlPoint2Y;
  };
}

const Banner = () => {
  const canvasRef = useRef(null);
  const translation = useTranslation();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const letterWriter = new CanvasLetterWriter(canvas);
    letterWriter.initializeCanvas();
    window.addEventListener("resize", letterWriter.handleWindowResize);

    return () => {
      letterWriter.clearCanvas();
      window.removeEventListener("resize", letterWriter.handleWindowResize);
    };
  }, []);

  return (
    <div
      style={{
        overflow: "hidden", // Hides flying icons
      }}
    >
      <div className="banner-overlay">
        <div className="main-container">
          <div className="banner-avatar-container">
            <a href={LINKEDIN_LINK} target="_blank" rel="noreferrer noopener">
              <motion.img
                whileHover={{ scale: 1.1 }}
                src={AvatarImage}
                className="banner-avatar"
              />
            </a>
            <h5 className="banner-sub-sub-title" style={{ marginTop: 10 }}>
              {translation.SEE_ME_ON}
              <a
                href={LINKEDIN_LINK}
                target="_blank"
                rel="noreferrer"
                className="banner-sub-sub-title-link"
              >
                <span className="banner-sub-sub-title-icon-container">
                  <LinkedInIcon className="banner-sub-sub-title-icon" />
                  LinkedIn
                </span>
              </a>
            </h5>
          </div>

          <div className="developer-text">
            <h4 className="banner-sub-sub-title-developer">
              {translation.FULL_STACK_WEB_DEVELOPER}
            </h4>
            <h5 className="banner-sub-sub-title-portfolio">
              {translation.CHECK_THIS_PORTFOLIO_ON}
              <a
                href={GITHUB_PORTFOLIO_LINK}
                target="_blank"
                rel="noreferrer"
                className="banner-sub-sub-title-link"
              >
                <span className="banner-sub-sub-title-icon-container">
                  <GithubIcon className="banner-sub-sub-title-icon" />
                  GitHub
                </span>
              </a>
            </h5>
          </div>

          <div className="banner-aws-certified">
            <a
              href="/aws-certificate.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              <motion.img
                whileHover={{ scale: 1.1 }}
                src={AwsCertifiedImage}
                width="220"
                height="220"
              />
            </a>
          </div>
        </div>
      </div>
      <canvas
        className="canvas"
        style={{ width: "100vw", height: CANVAS_HEIGHT }}
        ref={canvasRef}
      />
      <SkillIcons />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 2, duration: 2 }}
        className="banner-sub-title"
      ></motion.div>
    </div>
  );
};

export default Banner;
