import "./styles.css";

import {Canvas, extend, useFrame, useLoader, useThree} from "@react-three/fiber";
import React, {forwardRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {motion, useScroll, useTransform} from "framer-motion";
import {degreesToRadians, progress} from "popmotion";
import {DoubleSide, PlaneGeometry, TextureLoader} from "three";
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry';
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader';

import AimTrainingImage from './assets/aim-training.png';
import CheckoutFormImage from './assets/checkout-form.png';
import DictionaryImage from './assets/dictionary.png';
import DrumKitImage from './assets/drum-kit.png';
import FourSeasonsImage from './assets/four-seasons.png';
import GardenStoreImage from './assets/garden-store.png';
import GreenStoreImage from './assets/green-store.png';
import ImageGalleryImage from './assets/image-gallery.png';
import QuizImage from './assets/quiz.png';
import QuotesGeneratorImage from './assets/quotes-generator.png';
import RecipesImage from './assets/recipes.png';
import ResponsiveFormImage from './assets/responsive-form.png';
import SequenceGameImage from './assets/sequence-game.png';
import SparkleImage from './assets/sparkle.png';
import TwitterFeedImage from './assets/twitter-feed.png';
import WeatherImage from './assets/weather2.png';
import {ReactComponent as GithubIcon} from './assets/github2.svg'
import {useTranslation} from "../localization/translations";

extend({PlaneGeometry});

// Scroll positions that trigger visibility and animation for this component
const APPEAR_SCROLL_POSITION = 0;
const PAN_START_SCROLL_POSITION = 0.05;
const PAN_END_SCROLL_POSITION = 0.2;
const ROTATION_END_SCROLL_POSITION = 1.0;
const OVERLAY_APPEAR_SCROLL_POSITION = 0.15;
const OVERLAY_DISAPPEAR_SCROLL_POSITION = 0.96;

const useSideProjectItems = () => {
  const translation = useTranslation();
  return useMemo(() => [
    {
      title: translation.GARDEN_STORE_TITLE,
      description: translation.GARDEN_STORE_DESCRIPTION,
      image: GardenStoreImage,
      url: "https://garden-store-qvtt.onrender.com/",
      github: "https://github.com/daryakut/garden_store.git",
    },
    {
      title: translation.GREEN_STORE_TITLE,
      description: translation.GREEN_STORE_DESCRIPTION,
      image: GreenStoreImage,
      url: "https://daryakut.github.io/Green_Store/",
      github: "https://github.com/daryakut/Green_Store",
    },
    {
      title: translation.WEATHER_TITLE,
      description: translation.WEATHER_DESCRIPTION,
      image: WeatherImage,
      url: "https://react-weather-forecast-theta.vercel.app/",
      github: "https://github.com/daryakut/react-weather-forecast.git",
    },
    {
      title: translation.DICTIONARY_TITLE,
      description: translation.DICTIONARY_DESCRIPTION,
      image: DictionaryImage,
      url: "https://relaxed-griffin-3a55ba.netlify.app/",
      github: "https://github.com/daryakut/dictionary-project.git",
    },
    {
      title: translation.RECIPES_TITLE,
      description: translation.RECIPES_DESCRIPTION,
      image: RecipesImage,
      url: "https://recipes-app-psi-three.vercel.app/",
      github: "https://github.com/daryakut/recipes-app.git",
    },
    {
      title: translation.FOUR_SEASONS_TITLE,
      description: translation.FOUR_SEASONS_DESCRIPTION,
      image: FourSeasonsImage,
      url: "https://daryakut.github.io/seasons/",
      github: "https://github.com/daryakut/seasons",
    },
    {
      title: translation.IMAGE_GALLERY_TITLE,
      description: translation.IMAGE_GALLERY_DESCRIPTION,
      image: ImageGalleryImage,
      url: "https://daryakut.github.io/slider/",
      github: "https://github.com/daryakut/slider",
    },
    {
      title: translation.CHECKOUT_FORM_TITLE,
      description: translation.CHECKOUT_FORM_DESCRIPTION,
      image: CheckoutFormImage,
      url: "https://check-out-form.vercel.app/",
      github: "https://github.com/daryakut/-check-out-form-.git",
    },
    {
      title: translation.QUOTES_GENERATOR_TITLE,
      description: translation.QUOTES_GENERATOR_DESCRIPTION,
      image: QuotesGeneratorImage,
      url: "https://quotes-generator-ecru.vercel.app/",
      github: "https://github.com/daryakut/quotesGenerator.git"
    },
    {
      title: translation.RESPONSIVE_FORM_TITLE,
      description: translation.RESPONSIVE_FORM_DESCRIPTION,
      image: ResponsiveFormImage,
      url: "https://daryakut.github.io/myForm/",
      github: "https://github.com/daryakut/myForm",
    },
    {
      title: translation.QUIZ_TITLE,
      description: translation.QUIZ_DESCRIPTION,
      image: QuizImage,
      url: "https://react-quiz-game-beryl.vercel.app/",
      github: "https://github.com/daryakut/react-quiz-game.git",
    },
    {
      title: translation.TWITTER_FEED_TITLE,
      description: translation.TWITTER_FEED_DESCRIPTION,
      image: TwitterFeedImage,
      url: "https://daryakut.github.io/twitter/",
      github: "https://github.com/daryakut/twitter",
    },
    {
      title: translation.DRUM_KIT_TITLE,
      description: translation.DRUM_KIT_DESCRIPTION,
      image: DrumKitImage,
      url: "https://daryakut.github.io/Drum_Kit/",
      github: "https://github.com/daryakut/Drum_Kit",
    },
    {
      title: translation.SEQUENCE_GAME_TITLE,
      description: translation.SEQUENCE_GAME_DESCRIPTION,
      image: SequenceGameImage,
      url: "https://daryakut.github.io/sequence_game/",
      github: "https://github.com/daryakut/sequence_game",
    },
    {
      title: translation.SPARKLE_TITLE,
      description: translation.SPARKLE_DESCRIPTION,
      image: SparkleImage,
      url: "https://daryakut.github.io/sparkle/",
      github: "https://github.com/daryakut/sparkle",
    },
    {
      title: translation.AIM_TRAINING_TITLE,
      description: translation.AIM_TRAINING_DESCRIPTION,
      image: AimTrainingImage,
      url: "https://daryakut.github.io/Aim-Training/",
      github: "https://github.com/daryakut/Aim-Training",
    },
  ], [translation]);
}

const useRotationScrollPositions = () => {
  const sideProjectItems = useSideProjectItems()
  return useMemo(() => {
    const numSideProjects = sideProjectItems.length
    return Array(numSideProjects).fill(0).flatMap((_, index) => {
      // Stop at each project
      const stop = PAN_END_SCROLL_POSITION + (ROTATION_END_SCROLL_POSITION - PAN_END_SCROLL_POSITION) / numSideProjects * index;
      // We want to be able to stop for a bit so we need to add a small buffer when rotation is the same
      return [stop - 0.01, stop + 0.01]
      // return [stop - 0.02, stop]
    });
  }, [sideProjectItems])
}

const useRotationAngles = () => {
  const sideProjectItems = useSideProjectItems()

  return useMemo(() => {
    const numSideProjects = sideProjectItems.length
    return Array(numSideProjects).fill(0).flatMap((_, index) => {
      // How much to rotate for each project, 360 in total
      const rotation = degreesToRadians(360) / numSideProjects * index;
      // Rotation must be the same for both stops to have the "stop" effect
      return [rotation, rotation]
    });
  }, [sideProjectItems])
}

const useCurrentlyActiveProjectIndex = (scrollYProgress) => {
  const rotationScrollPositions = useRotationScrollPositions()
  return useMemo(
    () => {
      const activeProjectIndex = rotationScrollPositions.findIndex(
        (position, index) => {
          if (index % 2 === 1) {
            // We only look at even indices because the stops come in pairs
            return false
          }
          const nextPosition = rotationScrollPositions[index + 1];
          return position <= scrollYProgress && scrollYProgress < nextPosition;
        }
      );
      if (activeProjectIndex === -1) {
        return -1;
      }
      // Return the index of the project, not the stop
      return Math.round(activeProjectIndex / 2);
    },
    [rotationScrollPositions, scrollYProgress],
  )
};

const TextCylinder = ({font, scrollYProgress}) => {
  const translation = useTranslation();

  const textGeometry = new TextGeometry(translation.SIDE_PROJECTS, {
    font: font,
    size: 0.5,
    depth: 0.01,
  });
  textGeometry.center(); // Center the text geometry

  const mesh = useRef();
  // First increase opacity to 1, then once we fully zoom to first project, quickly decrease opacity to 0
  const opacity = useTransform(
    scrollYProgress,
    [APPEAR_SCROLL_POSITION, PAN_START_SCROLL_POSITION, PAN_END_SCROLL_POSITION * 0.8, PAN_END_SCROLL_POSITION, 1],
    [0, 1, 1, 0, 0],
  );

  useLayoutEffect(() => {
    mesh.current.rotation.x = Math.PI * 3 / 2;
    mesh.current.position.y = 1; // Adjust height position as needed
  }, []);

  return (
    <group ref={mesh}>
      <mesh>
        <cylinderGeometry args={[1, 1, 5, 32]}/>
        <meshBasicMaterial visible={false}/>
      </mesh>
      <mesh geometry={textGeometry}>
        <meshBasicMaterial color={"#555"} transparent opacity={opacity.get()}/>
      </mesh>
    </group>
  );
};

const ProjectWithScreenshot = (
  {
    projectIndex,
    numSideProjects,
    image,
    url,
    scrollYProgress,
  }
) => {
  const [aspectRatio, setAspectRatio] = useState(1); // Default to square if no image is loaded
  const ref = useRef(null);

  const texture = useLoader(TextureLoader, image)

  useEffect(() => {
    if (texture.image) {
      const {width, height} = texture.image;
      const aspectRatio = width / height;
      setAspectRatio(aspectRatio);
    }
  }, [texture]);

  const position = useTransform(
    scrollYProgress,
    [APPEAR_SCROLL_POSITION, PAN_START_SCROLL_POSITION],
    [12, 6],
  );
  const opacity = useTransform(
    scrollYProgress,
    [APPEAR_SCROLL_POSITION, PAN_START_SCROLL_POSITION],
    [0, 1],
  );

  const scrollProgress = progress(0, numSideProjects, projectIndex)
  const distance = position.get(); // Get the current position value
  useLayoutEffect(() => {
    const yAngle = degreesToRadians(90)
    const xAngle = degreesToRadians(360) * scrollProgress;
    ref.current.position.setFromSphericalCoords(distance, yAngle, xAngle);
    ref.current.lookAt(
      ref.current.position.x * 2, // Multiply by a factor to ensure it's facing directly away
      ref.current.position.y * 2,
      ref.current.position.z * 2
    );
  }, [scrollProgress, distance]);

  const currentlyActiveProjectIndex = useCurrentlyActiveProjectIndex(scrollYProgress.get())
  const handleOnClick = useCallback(() => {
    if (currentlyActiveProjectIndex === projectIndex) {
      window.open(url, '_blank');
    }
  }, [currentlyActiveProjectIndex, projectIndex, url]);

  const geometryWidth = 0.35 * window.innerWidth / 1024;
  return (
    <mesh ref={ref} onClick={handleOnClick}>
      <planeGeometry args={[aspectRatio * geometryWidth, geometryWidth]}/>
      <meshBasicMaterial map={texture} side={DoubleSide} transparent opacity={opacity.get()}/>
    </mesh>
  );
};

function SideProjectsScene(
  {
    sideProjectItems,
    scrollYProgress,
  }
) {
  const font = useLoader(FontLoader, '/helvetiker_regular.typeface.json');
  const gl = useThree((state) => state.gl);

  const distance = useTransform(
    scrollYProgress,
    [0, PAN_START_SCROLL_POSITION, PAN_END_SCROLL_POSITION],
    [10, 10, 6.5],
  );

  // Rotate the camera vertically to start facing individual project image at the end
  const yAngle = useTransform(
    scrollYProgress,
    [0, PAN_START_SCROLL_POSITION, PAN_END_SCROLL_POSITION * 0.9],
    [0.001, 0.001, degreesToRadians(90)],
  );

  const rotationScrollPositions = useRotationScrollPositions()
  const rotationAngles = useRotationAngles()
  // Rotate the camera horizontally to show all projects
  const zAngle = useTransform(
    scrollYProgress,
    rotationScrollPositions,
    rotationAngles,
  );

  useFrame(({camera}) => {
    const angleY = yAngle.get()
    const angleZ = zAngle.get()
    camera.position.setFromSphericalCoords(
      distance.get(),
      angleY,
      angleZ,
      // angleY * 1,
    );
    camera.updateProjectionMatrix();
    camera.lookAt(0, 0, 0);
  });

  useLayoutEffect(() => {
    gl.setPixelRatio(window.devicePixelRatio || 2);
  });

  const stars = sideProjectItems.map((item, index) => (
    <ProjectWithScreenshot
      key={index}
      projectIndex={index}
      numSideProjects={sideProjectItems.length}
      image={item.image}
      url={item.url}
      scrollYProgress={scrollYProgress}
    />
  ));

  return (
    <>
      <TextCylinder font={font} scrollYProgress={scrollYProgress}/>
      {stars}
    </>
  );
}

const SideProjects = ({headerScrollRef}) => {
  const translation = useTranslation();
  const containerRef = useRef(null);

  const [scrollYProgress, setScrollYProgress] = useState(0);
  const {scrollYProgress: motionScrollYProgress} = useScroll({target: containerRef});

  const sideProjectItems = useSideProjectItems()

  useEffect(() => {
    // This effect function will run whenever motionScrollYProgress changes.
    const unsubscribe = motionScrollYProgress.on('change', (latest) => {
      // Update local state with the latest scroll progress value
      setScrollYProgress(latest);
    });

    // Clean up subscription when component unmounts or motionScrollYProgress changes
    return unsubscribe;
  }, [motionScrollYProgress]);

  const overlayOpacity = useTransform(
    motionScrollYProgress,
    [OVERLAY_APPEAR_SCROLL_POSITION, PAN_END_SCROLL_POSITION, OVERLAY_DISAPPEAR_SCROLL_POSITION, 1],
    [0, 1, 1, 0],
  );

  const currentlyActiveProjectIndex = useCurrentlyActiveProjectIndex(scrollYProgress)

  const viewOnlineText = translation.VIEW_ONLINE;
  return (
    <div style={{
      width: '100%',
      height: '1600vh',
    }} ref={containerRef}>
      <div style={{
        position: 'sticky',
        top: '0',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
      }}>
        {/* This is a hack to ensure that we can scroll into the middle of the SideProjects from the header, so that animation already starts */}
        <div className="side-projects-scroll-ref" ref={headerScrollRef}/>
        <Canvas gl={{antialias: false}} camera={{position: [0, 0, 5]}}>
          <SideProjectsScene
            sideProjectItems={sideProjectItems}
            scrollYProgress={motionScrollYProgress}
          />
        </Canvas>
        <motion.div className="side-project-caption-overlay" style={{opacity: overlayOpacity}}>
          {currentlyActiveProjectIndex !== -1 ? (
            <div className="side-project-caption">
              <h3>{sideProjectItems[currentlyActiveProjectIndex].title}</h3>
              <p>{sideProjectItems[currentlyActiveProjectIndex].description}</p>
              <div className="side-project-links">
                {sideProjectItems[currentlyActiveProjectIndex].url ? (
                  <a
                    href={sideProjectItems[currentlyActiveProjectIndex].url}
                    target="_blank"
                    rel="noreferrer"
                    className="white-link"
                    style={{margin: 10}}
                  >
                    {viewOnlineText}
                  </a>
                ) : null}
                {sideProjectItems[currentlyActiveProjectIndex].github ? (
                  <a
                    href={sideProjectItems[currentlyActiveProjectIndex].github}
                    target="_blank"
                    rel="noreferrer"
                    className="white-link"
                    style={{margin: 10, marginLeft: 30}}
                  >
                    <div style={{position: 'relative'}}>
                      <GithubIcon width="30" height="30" style={{position: 'absolute', left: -30, top: -4}}/>
                      GitHub
                    </div>
                  </a>
                ) : null}
              </div>
            </div>
          ) : null}
        </motion.div>
      </div>
    </div>
  );
}

export default forwardRef((props, ref) => (
  <SideProjects headerScrollRef={ref}/>
));
