import {useContext} from "react";
import LocaleContext, {LOCALES} from "./LocaleContext";
import {TRANSLATIONS_EN} from "./translationsEn";
import {TRANSLATIONS_DE} from "./translationsDe";

export const useTranslation = () => {
  const {locale} = useContext(LocaleContext);
  if (locale === LOCALES.EN) {
    return TRANSLATIONS_EN;
  } else {
    return TRANSLATIONS_DE;
  }
}
