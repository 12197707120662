import './styles.css';
import React from 'react';
import {motion} from 'framer-motion';
import {Col, Row} from "antd";
import {ReactComponent as AwsIcon} from './assets/aws.svg'
import {ReactComponent as CssIcon} from './assets/css.svg'
import {ReactComponent as HtmlIcon} from './assets/html.svg'
import {ReactComponent as MongodbIcon} from './assets/mongodb.svg'
import {ReactComponent as MysqlIcon} from './assets/mysql.svg'
import {ReactComponent as NodeJsIcon} from './assets/node-js.svg'
import {ReactComponent as PythonIcon} from './assets/python.svg'
import {ReactComponent as ReactIcon} from './assets/react.svg'
import {ReactComponent as VueJsIcon} from './assets/vuejs.svg'
import {ReactComponent as JavascriptIcon} from './assets/javascript.svg'

const MotionAwsIcon = motion(AwsIcon)
const MotionCssIcon = motion(CssIcon)
const MotionHtmlIcon = motion(HtmlIcon)
const MotionMongodbIcon = motion(MongodbIcon)
const MotionMysqlIcon = motion(MysqlIcon)
const MotionNodeJsIcon = motion(NodeJsIcon)
const MotionPythonIcon = motion(PythonIcon)
const MotionReactIcon = motion(ReactIcon)
const MotionVueJsIcon = motion(VueJsIcon)
const MotionJavascriptIcon = motion(JavascriptIcon)

const STANDARD_ICON_STYLE = {width: 50, height: 50, margin: 10};

const BACKEND_ICONS = [
  {IconComponent: MotionNodeJsIcon},
  {IconComponent: MotionPythonIcon},
  {IconComponent: MotionAwsIcon},
  {IconComponent: MotionMysqlIcon},
  {IconComponent: MotionMongodbIcon},
]

const SkillIcons = () => {
  const delays = new Array(5).fill(null).map((_, index) => ({
    delay: 0.2 * index,
  }))

  return (
    <Row>
      <Col span={12} className="banner-icons-column">
        <div className="banner-icons-container">
          <div className="banner-center-content">
            <MotionJavascriptIcon
              style={STANDARD_ICON_STYLE}
              initial={{x: -500, opacity: 0}}
              animate={{x: 0, opacity: 1}}
              transition={{
                delay: delays[0].delay,
                duration: 0.5,
                type: 'spring',
                bounce: 0.7,
                damping: 15,
                mass: 1,
              }}
            >
            </MotionJavascriptIcon>
            <MotionHtmlIcon
              style={STANDARD_ICON_STYLE}
              initial={{rotateZ: -45}}
              animate={{rotateZ: 0}}
              transition={{
                delay: delays[1].delay,
                duration: 0.5,
                type: 'spring',
                stiffness: 500,
              }}
            >
            </MotionHtmlIcon>
            <MotionCssIcon
              style={STANDARD_ICON_STYLE}
              initial={{scale: 0}}
              animate={{scale: 1}}
              transition={{
                delay: delays[2].delay,
                duration: 1,
                type: 'spring',
                stiffness: 100,
              }}
            >
            </MotionCssIcon>
            <MotionReactIcon
              style={STANDARD_ICON_STYLE}
              initial={{rotateY: 90}}
              animate={{rotateY: 360}}
              transition={{
                delay: delays[3].delay,
                duration: 1,
                type: 'spring',
                stiffness: 100,
              }}
            >
            </MotionReactIcon>
            <MotionVueJsIcon
              style={STANDARD_ICON_STYLE}
              initial={{scaleY: 0}}
              animate={{scaleY: 1}}
              transition={{
                delay: delays[4].delay,
                duration: 1,
                type: 'spring',
                stiffness: 100,
              }}
            >
            </MotionVueJsIcon>
          </div>
          <div className="banner-flex-column">
            <span className="banner-skills-text-row">
              JavaScript | TypeScript | React | Vue.js
            </span>
            <span className="banner-skills-text-row">
              HTML | CSS | Sass | Jest
            </span>
          </div>
        </div>
      </Col>
      <Col span={12} className="banner-icons-column">
        <div className="banner-icons-container">
          <div className="banner-center-content">
            {BACKEND_ICONS.map(({IconComponent}, index) => (
              <IconComponent
                key={index}
                style={STANDARD_ICON_STYLE}
                initial={{rotateZ: 0, opacity: 0, x: 500}}
                animate={{rotateZ: 360 * 3, opacity: 1, x: 0}}
                transition={{
                  delay: 0.2 * index + 1.0,
                  duration: 2,
                  type: 'spring',
                  bounce: 0.7,
                  damping: 15,
                }}
              />
            ))}
          </div>
          <div className="banner-flex-column">
            <span className="banner-skills-text-row">
              Node.js | Express.js | Python | RESTful API
            </span>
            <span className="banner-skills-text-row">
              AWS | MySQL | MongoDB | Linux
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SkillIcons;
