import './styles.css';
import HupresDemo from "../hupres/HupresDemo";
import RelatedUsers from "../related_users/RelatedUsers";
import Swishboom from "../swishboom/Swishboom";
import { forwardRef } from "react";
import {useTranslation} from "../localization/translations";

const Projects = ({ headerScrollRef }) => {
  const translation = useTranslation();

  return (
    <>
      <div ref={headerScrollRef} className="projects-container">
        <h2>{translation.PROJECTS}</h2>
      </div>
      <HupresDemo/>
      {/* <RelatedUsers/> */}
      <Swishboom/>
    </>
  )
};

export default forwardRef((props, ref) => (
  <Projects headerScrollRef={ref} />
));
