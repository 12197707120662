import axios from 'axios';

console.log('process.env.REACT_APP_PF_STAGE', process.env.REACT_APP_PF_STAGE)

const apiClient = axios.create({
  // Server is running on a different port (8088), but dues to `proxy` in package.json, we can use the same port
  // This is needed to be able to pass cookies from frontend to backend (otherwise they won't be sent)
  baseURL: process.env.REACT_APP_PF_STAGE === 'prod' ? 'https://api.kutluieva.com/' : 'http://localhost:3000/',
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true, // Send cookies cross-origin with every request
});

export default apiClient;
