import './App.css';

import SideProjects from "./components/side_projects/SideProjects";
import Banner from "./components/banner/Banner";
import Projects from "./components/projects/Projects";
import Chat from "./components/chat/Chat";
import Header from "./components/header/Header";
import {LocaleProvider} from "./components/localization/LocaleContext";
import {useRef} from "react";
import Footer from "./components/footer/Footer";

export default function App() {
  // // Preload images as we don't need them immediately visible
  // usePreloadImages();

  const projectsRef = useRef(null);
  const sideProjectsRef = useRef(null);
  const chatRef = useRef(null);

  return (
    <>
      <LocaleProvider>
        <Header
          projectsRef={projectsRef}
          sideProjectsRef={sideProjectsRef}
          chatRef={chatRef}
        />
        <Banner/>
        <Projects ref={projectsRef}/>
        <SideProjects ref={sideProjectsRef}/>
        <Chat ref={chatRef}/>
        <Footer/>
      </LocaleProvider>
    </>
  );
}
