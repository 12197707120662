import {createContext, useState} from "react";

export const LOCALES = {
  EN: 'en',
  DE: 'de',
};

const LocaleContext = createContext({locale: LOCALES.EN});

export default LocaleContext;

export const LocaleProvider = ({children}) => {
  const [locale, setLocale] = useState(LOCALES.EN);

  const toggleLocale = () => {
    setLocale(prevLocale => prevLocale === LOCALES.EN ? LOCALES.DE : LOCALES.EN);
  };

  return (
    <LocaleContext.Provider value={{locale, toggleLocale}}>
      {children}
    </LocaleContext.Provider>
  );
};
