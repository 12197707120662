import './styles.css';

import {ReactComponent as FaviconSvg} from './assets/favicon-white.svg'
import {ReactComponent as DeSvg} from './assets/de.svg'
import {ReactComponent as EnSvg} from './assets/en.svg'
import {useContext} from "react";
import LocaleContext, {LOCALES} from "../localization/LocaleContext";
import {useTranslation} from "../localization/translations";

const LocaleIcon = () => {
  const {locale, toggleLocale} = useContext(LocaleContext);

  return (
    <div onClick={toggleLocale}>
      {locale === LOCALES.EN ? (
        <DeSvg className="header-locale-icon-svg"/>
      ) : (
        <EnSvg className="header-locale-icon-svg"/>
      )}
    </div>
  )
}

const Header = ({projectsRef, sideProjectsRef, chatRef}) => {
  const translation = useTranslation();

  return (
    <>
      <div className="header-container">
        <a href="/">
          <FaviconSvg className="header-favicon"/>
        </a>
        <div className="header-links">
          <a
            href="/resume.pdf"
            target="_blank"
            rel="noreferrer"
            className="header-link"
          >{translation.RESUME}</a>
          <div
            className="header-link"
            onClick={() => projectsRef.current.scrollIntoView({behavior: 'smooth'})}
          >{translation.PROJECTS}</div>
          <div
            className="header-link"
            onClick={() => sideProjectsRef.current.scrollIntoView({behavior: 'smooth'})}
          >{translation.SIDE_PROJECTS}</div>
          <div
            className="header-link"
            onClick={() => chatRef.current.scrollIntoView({behavior: 'smooth'})}
          >{translation.LETS_CHAT}</div>
        </div>
        <LocaleIcon/>
      </div>
    </>
  )
};

export default Header;
