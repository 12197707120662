import './styles.css';

import {motion, useScroll, useTransform} from 'framer-motion';
import React, {useMemo, useRef} from 'react';

import {ReactComponent as EmailSvg} from './assets/email.svg'
import {ReactComponent as LocationSvg} from './assets/location.svg'
import {ReactComponent as PhoneSvg} from './assets/phone.svg'
import {ReactComponent as User1Svg} from './assets/user1.svg'
import {ReactComponent as User2Svg} from './assets/user2.svg'
import {ReactComponent as User3Svg} from './assets/user3.svg'
import {ReactComponent as User4Svg} from './assets/user4.svg'
import {ReactComponent as LogoSvg} from './assets/logo2.svg'
import FeaturesList from "../common/FeaturesList";
import {useTranslation} from "../localization/translations";

const MotionLocationSvg = motion(LocationSvg)
const MotionPhoneSvg = motion(PhoneSvg)
const MotionEmailSvg = motion(EmailSvg)
const MotionUser1Svg = motion(User1Svg)
const MotionUser2Svg = motion(User2Svg)
const MotionUser3Svg = motion(User3Svg)
const MotionUser4Svg = motion(User4Svg)

const FEATURE_ITEM_SCROLL_OFFSET = 0.5;

const BUBBLE_TRANSLATE = {x: 40, y: -29};

const SpeechBubbleSvg = ({x, y, width, height, line1, line2, opacity}) => {
  const bubblePointer = useMemo(() => [
    `${10 + x + BUBBLE_TRANSLATE.x},${30 + y + BUBBLE_TRANSLATE.y}`,
    `${0 + x + BUBBLE_TRANSLATE.x},${50 + y + BUBBLE_TRANSLATE.y}`,
    `${20 + x + BUBBLE_TRANSLATE.x},${40 + y + BUBBLE_TRANSLATE.y}`,
  ], [x, y])
  return (
    <motion.svg style={{opacity}}>
      <rect
        x={10 + x + BUBBLE_TRANSLATE.x}
        y={0 + y + BUBBLE_TRANSLATE.y}
        width={width}
        height={height}
        rx="10"
        fill="white"
        stroke="black"
      />
      <polygon
        points={bubblePointer.join(' ')}
        fill="white"
      />
      <text
        x={20 + x + BUBBLE_TRANSLATE.x}
        y={20 + y + BUBBLE_TRANSLATE.y}
        fontFamily="Arial"
        fontSize="12"
      >
        <tspan x={20 + x + BUBBLE_TRANSLATE.x} dy="0em">
          {line1}
        </tspan>
        {line2 ? (
          <tspan x={20 + x + BUBBLE_TRANSLATE.x} dy="1.2em">
            {line2}
          </tspan>
        ) : null}
      </text>
    </motion.svg>
  )
}

const RelatedUsers = () => {
  const translation = useTranslation();

  const featureItems = [
    `🔒 ${translation.SECURE_RECORDING}`,
    `👤 ${translation.ADMIN_PANEL}`,
    `🧠 ${translation.VERSIONING_ML}`,
    `📊 ${translation.DATA_EXPORT}`,
  ];

  const containerRef = useRef(null);

  const {scrollYProgress} = useScroll({
    target: containerRef,
  });

  const svgProgress = useTransform(scrollYProgress, [0, 0.5], [0, 1]);

  // Convert scroll progress into stroke offset for each line
  const line1VerticalOffset = useTransform(svgProgress, [0.1, 0.2], [80, 0]);
  const line1HorizontalOffset = useTransform(svgProgress, [0.15, 0.35], [80, 0]);
  const line2VerticalOffset = useTransform(svgProgress, [0.3, 0.5], [80, 0]);
  const line2HorizontalOffset = useTransform(svgProgress, [0.45, 0.65], [80, 0]);
  const line3VerticalOffset = useTransform(svgProgress, [0.6, 0.8], [80, 0]);
  const line3HorizontalOffset = useTransform(svgProgress, [0.75, 0.95], [80, 0]);

  const circle1Opacity = useTransform(svgProgress, [0, 0.1], [0, 1]);
  const circle2Opacity = useTransform(svgProgress, [0.15, 0.2], [0, 1]);
  const circle3Opacity = useTransform(svgProgress, [0.45, 0.5], [0, 1]);
  const circle4Opacity = useTransform(svgProgress, [0.75, 0.8], [0, 1]);
  const circle2ROpacity = useTransform(svgProgress, [0.3, 0.35], [0, 1]);
  const circle3ROpacity = useTransform(svgProgress, [0.6, 0.65], [0, 1]);
  const circle4ROpacity = useTransform(svgProgress, [0.9, 0.95], [0, 1]);

  return (
    <div style={{
      width: '100%',
      height: '550vh',
    }} ref={containerRef}>
      <div style={{
        position: 'sticky',
        top: '0',
        width: '100%',
        height: '100vh',
        zIndex: '1',
      }} className="related-users-container">
        <div className="related-users-header">
          <div className="related-users-logo-container">
            <a href="https://realtedusers.com" target="_blank" rel="noreferrer">
              <LogoSvg width="360" height="100%"/>
            </a>
            <p>
              <a
                href="https://realtedusers.com"
                target="_blank"
                rel="noreferrer"
                className="white-link"
              >relatedusers</a>
              &nbsp;{translation.RELATED_USERS_DESCRIPTION}
            </p>
          </div>
        </div>
        <div className="related-users-content">
          <svg width="650" height="700" viewBox="60 0 430 400" xmlns="http://www.w3.org/2000/svg">
            <MotionUser1Svg x="80" y="30" width="40" height="40" style={{opacity: circle1Opacity}}/>
            <SpeechBubbleSvg
              x={80}
              y={30}
              width={140}
              height={45}
              line1={translation.SPEECH_BUBBLE_USER_1}
              opacity={circle1Opacity}
            />

            <MotionEmailSvg x="80" y="130" width="40" height="40" style={{opacity: circle2Opacity}}/>
            <MotionUser2Svg x="180" y="130" width="40" height="40" style={{opacity: circle2ROpacity}}/>
            <SpeechBubbleSvg
              x={180}
              y={130}
              width={195}
              height={45}
              line1={translation.SPEECH_BUBBLE_SAME_EMAIL_1}
              line2={translation.SPEECH_BUBBLE_SAME_EMAIL_2}
              opacity={circle2ROpacity}
            />

            <MotionPhoneSvg x="80" y="230" width="40" height="40" style={{opacity: circle3Opacity}}/>
            <MotionUser3Svg x="180" y="230" width="40" height="40" style={{opacity: circle3ROpacity}}/>
            <SpeechBubbleSvg
              x={180}
              y={230}
              width={195}
              height={45}
              line1={translation.SPEECH_BUBBLE_SAME_PHONE_1}
              line2={translation.SPEECH_BUBBLE_SAME_PHONE_2}
              opacity={circle3ROpacity}
            />

            <MotionLocationSvg x="80" y="330" width="40" height="40" style={{opacity: circle4Opacity}}/>
            <MotionUser4Svg x="180" y="330" width="40" height="40" style={{opacity: circle4ROpacity}}/>
            <SpeechBubbleSvg
              x={180}
              y={330}
              width={195}
              height={45}
              line1={translation.SPEECH_BUBBLE_SAME_LOCATION_1}
              line2={translation.SPEECH_BUBBLE_SAME_LOCATION_2}
              opacity={circle4ROpacity}
            />

            <motion.path
              className="related-users-svg-line"
              d="M100,70 L100,130"
              style={{strokeDashoffset: line1VerticalOffset}}
            />
            <motion.path
              className="related-users-svg-line"
              d="M100,170 L100,230"
              style={{strokeDashoffset: line2VerticalOffset}}
            />
            <motion.path
              className="related-users-svg-line"
              d="M100,270 L100,330"
              style={{strokeDashoffset: line3VerticalOffset}}
            />
            <motion.path
              className="related-users-svg-line"
              d="M120,150 L190,150"
              style={{strokeDashoffset: line1HorizontalOffset}}
            />
            <motion.path
              className="related-users-svg-line"
              d="M120,250 L190,250"
              style={{strokeDashoffset: line2HorizontalOffset}}
            />
            <motion.path
              className="related-users-svg-line"
              d="M120,350 L190,350"
              style={{strokeDashoffset: line3HorizontalOffset}}
            />
          </svg>
          <div className="related-users-features">
            <FeaturesList
              featureTexts={featureItems}
              scrollOffset={FEATURE_ITEM_SCROLL_OFFSET}
              scrollYProgress={scrollYProgress}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedUsers;
