export const TRANSLATIONS_DE = {
  // -- Header --
  // Label for a link that points to the user's resume
  RESUME: "Lebenslauf",
  // Label for a navigation link to the projects section
  PROJECTS: "Projekte",
  // Label for a navigation link to the side projects section
  SIDE_PROJECTS: "Nebenprojekte",
  // Text for a navigation link that initiates a chat with an AI assistant
  LETS_CHAT: "Lass uns chatten!",

  // -- Banner --
  // Used in the LinkedIn profile link as alt text and screen reader text, reads "Check me on LinkedIn"
  SEE_ME_ON: "Finde mich auf",
  // Full-Stack Web Developer displayed as a profession description
  FULL_STACK_WEB_DEVELOPER: "Webentwicklerin",
  // Text indicating where the user's portfolio can be checked, reads "Check this portfolio on Github"
  CHECK_THIS_PORTFOLIO_ON: "Meines Portfolio auf GitHub ansehen",

  // -- chat.hupres.com --
  // Used in feature list
  AI_CHATBOT: "KI-Chatbot",
  // Used in feature list
  PSYCHOLOGICAL_TEST: "Psychologischer Test",
  // Used in feature list
  PROFILES_VIEWING: "Profilansicht",
  // Used in feature list
  RESPONSIVE_DESIGN: "Responsive Design",
  // Describes the nature of the website, reads "chat.hupres.com is an online psychological AI assistant"
  ONLINE_PSYCHOLOGICAL_ASSISTANT: "ist ein Online-psychologischer KI-Assistent",

  // Used as an initial greeting from the bot
  GREETING: "Hallo!",
  // Describes the bot's purpose
  INTRO_MESSAGE:
    "Ich bin ein psychologischer KI-Assistent, ich kann dir helfen, dich selbst besser zu verstehen.",
  // Introduction to the questionnaire
  QUESTIONNAIRE_INTRO:
    "Zuerst werde ich dir einige Fragen stellen, dann gebe ich dir eine Zusammenfassung deines psychologischen Profils.",
  // Invitation to interact more with the bot
  CHAT_INVITATION:
    "Du kannst auch mit mir chatten, um spezifischere Fragen zu stellen und Empfehlungen für deine Karriere und dein Leben zu erhalten.",

  // Asks for the user's height
  HEIGHT_PROMPT: "Bitte teile mir mit, ob du:",
  // Option for user being taller than average
  TALLER_THAN_AVERAGE: "Überdurchschnittlich groß",
  // Option for user being shorter than average
  SHORTER_THAN_AVERAGE: "Unterdurchschnittlich groß",
  // Option for average height
  AVERAGE_HEIGHT: "Durchschnittliche Größe",

  // Asks for the user's eye color
  EYES_PROMPT: "Bitte teile mir mit, ob du:",
  // Option for brown eye color
  BROWN_EYES: "Braune Augen",
  // Option for blue eye color
  BLUE_EYES: "Blaue Augen",
  // Option for green eye color
  GREEN_EYES: "Grüne Augen",
  // Option for other eye colors
  OTHER_EYES: "Andere",

  // Asks for the user's hair type
  HAIR_PROMPT: "Bitte teile mir mit, ob du:",
  // Option for straight hair
  STRAIGHT_HAIR: "Glattes Haar",
  // Option for wavy hair
  WAVY_HAIR: "Gewelltes Haar",
  // Option for thick hair
  THICK_HAIR: "Dickes Haar",
  // Option for thin hair
  THIN_HAIR: "Dünnes Haar",

  // Introduction to profile summary
  PROFILE_SUMMARY:
    "Danke! Nun werde ich dir eine Zusammenfassung deines psychologischen Profils geben.",
  // Affirmation of the user's uniqueness
  UNIQUE_STATEMENT: "Unabhängig davon, du bist einzigartig und besonders!",
  // Simple thank-you response from the user
  THANKS_RESPONSE: "Danke!",

  // -- relatedusers.com --
  // Text displayed in the speech bubble for the first user
  SPEECH_BUBBLE_USER_1: "Ich bin Anna",

  // Text displayed in the speech bubble indicating usage of same email
  SPEECH_BUBBLE_SAME_EMAIL_1: "Ich habe dieselbe",
  SPEECH_BUBBLE_SAME_EMAIL_2: "E-Mail wie Anna verwendet",

  // Text displayed in the speech bubble indicating usage of same phone
  SPEECH_BUBBLE_SAME_PHONE_1: "Ich habe dasselbe",
  SPEECH_BUBBLE_SAME_PHONE_2: "Telefon wie Anna verwendet",

  // Text displayed in the speech bubble indicating usage of same location
  SPEECH_BUBBLE_SAME_LOCATION_1: "Ich habe denselben",
  SPEECH_BUBBLE_SAME_LOCATION_2: "Standort wie Anna verwendet",

  // Text description for relatedusers.com link, reads "relatedusers.com is a B2B anti-fraud platform that helps detect clusters of bad actors"
  RELATED_USERS_DESCRIPTION:
    "ist eine B2B-Betrugsbekämpfungsplattform, die dabei hilft, Cluster von betrügerischen Akteuren zu erkennen",

  // Feature item: Indicates the feature of secure recording in the application
  SECURE_RECORDING: "Sichere Aufzeichnung von Attributen",
  // Feature item: Describes the admin panel capability for exploring user data
  ADMIN_PANEL: "Admin-Panel zur Benutzerexploration",
  // Feature item: Represents the version control feature in machine learning development
  VERSIONING_ML:
    "Versionsverwaltung für die Entwicklung von maschinellem Lernen",
  // Feature item: Describes the feature for exporting data for analytics purposes
  DATA_EXPORT: "Datenexport für Analysen",

  // -- SwishBOOM --
  // Description of the online marketplace provided on the logo container
  SWISHBOOM_DESCRIPTION:
    "SwishBOOM war ein Online-Marktplatz, auf dem Familien Babysitter-Termine bei Betreuungspersonen buchen konnten",
  // Feature item: User authentication functionality
  FEATURE_USER_AUTH: "Benutzerauthentifizierung",
  // Feature item: Booking consultations
  FEATURE_BOOK_CONSULTATION: "Buchung einer Beratung",
  // Feature item: Integration with Google Maps
  FEATURE_GOOGLE_MAP: "Google Maps-Integration",
  // Feature item: Ratings and reviews system
  FEATURE_RATINGS_REVIEWS: "Bewertungs- und Rezensionssystem",
  // Feature item: Verified network of users
  FEATURE_VERIFIED_NET: "Verifiziertes Netzwerk",
  // Feature item: Referral rewarding system
  FEATURE_REF_REWARD: "Empfehlungs-Belohnungssystem",
  // Feature item: Settings options for users
  FEATURE_SETTINGS: "Einstellungen",

  // -- Side Projects --
  // Title of the link that leads to the online version of the project
  VIEW_ONLINE: "Online ansehen",
  // Title for a garden equipment and supplies online store.
  GARDEN_STORE_TITLE: "Gartengeschäft",
  // Description for a garden equipment and supplies online store.
  GARDEN_STORE_DESCRIPTION: "Online-Shop für Gartengeräte und -bedarf.",
  // Title for an eco-friendly products online store.
  GREEN_STORE_TITLE: "Öko-Shop",
  // Description for an eco-friendly products online store front page.
  GREEN_STORE_DESCRIPTION: "Öko-freundlicher Online-Shop.",
  // Title for a weather forecasting application.
  WEATHER_TITLE: "Wetter",
  // Description of the weather forecasting application covering weekly forecasts.
  WEATHER_DESCRIPTION: "Wettervorhersage-App für eine Woche.",
  // Title for an online dictionary application.
  DICTIONARY_TITLE: "Wörterbuch",
  // Description of the online dictionary application.
  DICTIONARY_DESCRIPTION: "Eine Online-Wörterbuch-App.",
  // Title for a collection of recipes.
  RECIPES_TITLE: "Rezepte",
  // Description of the collection of recipes.
  RECIPES_DESCRIPTION: "Eine Sammlung von Rezepten.",
  // Title for an animated image gallery.
  FOUR_SEASONS_TITLE: "Vier Jahreszeiten",
  // Description of the animated image gallery.
  FOUR_SEASONS_DESCRIPTION: "Animierte Bildergalerie.",
  // Title for a responsive image gallery.
  IMAGE_GALLERY_TITLE: "Bildergalerie",
  // Description of the beautiful and responsive image gallery.
  IMAGE_GALLERY_DESCRIPTION: "Eine schöne und responsive Bildergalerie.",
  // Title for a checkout form used in e-commerce websites.
  CHECKOUT_FORM_TITLE: "Kassenformular",
  // Description of the checkout form used in e-commerce websites.
  CHECKOUT_FORM_DESCRIPTION: "Ein Kassenformular für E-Commerce-Websites.",
  // Title for a motivational quotes generator.
  QUOTES_GENERATOR_TITLE: "Zitatengenerator",
  // Description of the motivational quotes generator.
  QUOTES_GENERATOR_DESCRIPTION:
    "Generiert inspirierende Zitate, um dich zu motivieren.",
  // Title for a responsive form suitable for modern web applications.
  RESPONSIVE_FORM_TITLE: "Responsives Formular",
  // Description of the responsive form suitable for modern web applications.
  RESPONSIVE_FORM_DESCRIPTION:
    "Responsives Formular für moderne Webanwendungen.",
  // Title for an interactive quiz to test React knowledge.
  QUIZ_TITLE: "Quiz",
  // Description of the interactive quiz to test React knowledge.
  QUIZ_DESCRIPTION: "Interaktives Quiz, um dein React-Wissen zu testen.",
  // Title for a Twitter feed simulation.
  TWITTER_FEED_TITLE: "Twitter-Feed",
  // Description of the Twitter feed simulation.
  TWITTER_FEED_DESCRIPTION: "Eine Simulation eines Twitter-Feeds.",
  // Title for a virtual drum kit.
  DRUM_KIT_TITLE: "Schlagzeug-Set",
  // Description of the virtual drum kit.
  DRUM_KIT_DESCRIPTION:
    "Virtuelles Schlagzeug, um online Schlagzeug zu spielen.",
  // Title for a sequence memory game.
  SEQUENCE_GAME_TITLE: "Sequenzspiel",
  // Description of the sequence memory game.
  SEQUENCE_GAME_DESCRIPTION:
    "Ein unterhaltsames Sequenzgedächtnisspiel, um dein Gedächtnis zu verbessern.",
  // Title for a mouse animation game.
  SPARKLE_TITLE: "Funkeln",
  // Description of the mouse animation game.
  SPARKLE_DESCRIPTION: "Ein unterhaltsames Maus-Animationsspiel!",
  // Title for a reflex testing game.
  AIM_TRAINING_TITLE: "Reaktionstraining",
  // Description of the reflex testing game.
  AIM_TRAINING_DESCRIPTION: "Ein lustiges Spiel, um deine Reflexe zu testen.",

  // -- Chat --
  // Introductory message from the AI
  AI_INTRO_MESSAGE:
    "Hallo! Ich bin Darias persönlicher KI-Assistent, ich kann alle Fragen zu ihren Fähigkeiten, Erfahrungen oder Verfügbarkeiten beantworten!",
  // Follow-up message asking how the AI can assist
  AI_HELP_PROMPT: "Wie kann ich Ihnen heute helfen?",
  // Placeholder text in message input field
  MESSAGE_INPUT_PLACEHOLDER: "Geben Sie eine Nachricht ein...",
  // Button caption for sending a message
  SEND_BUTTON_TEXT: "Senden",

  // -- Footer --
  // Text on a button that scrolls the user back to the top of the page
  BACK_TO_TOP: "Zurück nach oben",
};
