const SEGMENT_STROKE_LENGTH_PER_FRAME = 10;

// Nice tool to see what different fonts look like
// https://wordmark.it/

export const LETTER_WIDTH = 3;

class LineSegment {
  // Duration in frames on how long to take effect
  durationFrames = null;

  // How much to move the line in the x direction with every frame
  movementPerFrame = null;

  // How much to rotate the matrix with every frame
  rotationPerFrame = null;

  // Resets the current rotation to the given value at the start
  rotationAtStart = null;

  // How much to scale the matrix with every frame
  scalePerFrame = null;

  // How much to multiply the width of the line with every frame
  widthMultiplierPerFrame = null;

  // Resets the current width to the given value at the start
  widthAtStart = null;

  constructor({
                totalLength,
                totalRotation = null,
                rotationAtStart = null,
                totalScaleChange = null,
                totalWidthChange = null,
                widthAtStart = null,
              }) {
    const durationFrames = totalLength / SEGMENT_STROKE_LENGTH_PER_FRAME;
    this.durationFrames = durationFrames;
    this.movementPerFrame = totalLength / durationFrames;
    this.rotationPerFrame = totalRotation ? totalRotation / durationFrames : null;
    this.rotationAtStart = rotationAtStart;
    this.scalePerFrame = totalScaleChange ? Math.pow(totalScaleChange, 1.0 / durationFrames) : null;
    this.widthMultiplierPerFrame = totalWidthChange ? Math.pow(totalWidthChange, 1.0 / durationFrames) : null;
    this.widthAtStart = widthAtStart;
  }
}

const D = () => ({
  displacement: {
    x: 0,
    y: 0,
  },
  dimensions: {
    x: 150,
    y: 0,
  },
  lines: [
    // Round par of "D"
    [
      new LineSegment({
        totalLength: 30,
        widthAtStart: 3,
        rotationAtStart: Math.PI / 2,
      }),
      new LineSegment({
        totalLength: 150,
        totalWidthChange: 3.0,
        totalRotation: -Math.PI / 2,
      }),
      new LineSegment({
        totalLength: 150,
        totalWidthChange: 1.0 / 3.0,
        totalRotation: -Math.PI / 2,
      }),
      new LineSegment({
        totalLength: 30,
      }),
      new LineSegment({
        totalLength: 100,
        totalScaleChange: 0.1,
        totalRotation: Math.PI,
      }),
    ],
    // Vertical part of "D"
    [
      new LineSegment({
        totalLength: 15,
        widthAtStart: 3,
        rotationAtStart: Math.PI / 2,
      }),
      new LineSegment({
        totalLength: 195,
        rotationAtStart: -Math.PI / 2,
      }),
    ]
  ]
});

const a = () => ({
  displacement: {
    x: 130,
    y: 0,
  },
  dimensions: {
    x: 150,
    y: 0,
  },
  lines: [
    // Vertical part of "a"
    [
      new LineSegment({
        totalLength: 50,
        widthAtStart: 3,
        rotationAtStart: -Math.PI * 0.5,
        totalRotation: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 35,
      }),
      new LineSegment({
        totalLength: 70,
        totalRotation: -Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 30,
        totalRotation: -Math.PI * 0.75,
      }),
      new LineSegment({
        totalLength: 30,
        widthAtStart: 7,
        totalScaleChange: 0.1,
        totalRotation: -Math.PI * 0.3,
      }),
    ],
    // Round part of "a"
    [
      new LineSegment({
        totalLength: 50,
        widthAtStart: 3,
        rotationAtStart: -Math.PI * 0.5,
        totalRotation: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 95,
        rotationAtStart: -Math.PI * 0.9,
        totalRotation: Math.PI * 0.9,
      }),
      new LineSegment({
        totalLength: 95,
        totalRotation: Math.PI * 0.9,
      }),
      new LineSegment({
        totalLength: 50,
        totalRotation: Math.PI * 0.3,
        totalScaleChange: 0.1,
      }),
    ]
  ]
});

const r = () => ({
  displacement: {
    x: -10,
    y: 0,
  },
  dimensions: {
    x: 100,
    y: 0,
  },
  lines: [
    // Horizontal part of "r"
    [
      new LineSegment({
        totalLength: 20,
        widthAtStart: 3,
        rotationAtStart: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 20,
        totalWidthChange: 0.3,
      }),
    ],
    // Vertical part of "r"
    [
      new LineSegment({
        totalLength: 20,
        widthAtStart: 3,
        rotationAtStart: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 110,
        rotationAtStart: -Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 50,
        widthAtStart: 5,
        totalScaleChange: 0.1,
        rotationAtStart: -Math.PI * 0.5,
      }),
    ],
    // Vertical and round part of "r"
    [
      new LineSegment({
        totalLength: 20,
        widthAtStart: 3,
        rotationAtStart: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 60,
        rotationAtStart: -Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 80,
        totalRotation: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 30,
        totalRotation: Math.PI * 0.75,
      }),
      new LineSegment({
        totalLength: 30,
        widthAtStart: 7,
        totalScaleChange: 0.1,
        totalRotation: Math.PI * 0.3,
      }),
    ]
  ]
});

const i = () => ({
  displacement: {
    x: -10,
    y: 0,
  },
  dimensions: {
    x: 40,
    y: 0,
  },
  lines: [
    // Horizontal part of "i"
    [
      new LineSegment({
        totalLength: 20,
        widthAtStart: 3,
        rotationAtStart: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 20,
        totalWidthChange: 0.3,
      }),
    ],
    // Vertical part of "i"
    [
      new LineSegment({
        totalLength: 20,
        widthAtStart: 3,
        rotationAtStart: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 120,
        rotationAtStart: -Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 5,
        rotationAtStart: -Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 90,
        totalScaleChange: 0.2,
        totalWidthChange: 2.0,
        rotationAtStart: Math.PI * 0.5,
        totalRotation: Math.PI * 2,
      }),
    ],
  ]
});

const K = () => ({
  displacement: {
    x: 100,
    y: 0,
  },
  dimensions: {
    x: 230,
    y: 0,
  },
  lines: [
    // Round top par of "K"
    [
      new LineSegment({
        totalLength: 15,
        widthAtStart: 3,
        rotationAtStart: Math.PI / 2,
      }),
      new LineSegment({
        totalLength: 100,
        rotationAtStart: -Math.PI / 2,
      }),
      new LineSegment({
        totalLength: 10,
        rotationAtStart: Math.PI / 2 * 0.9,
      }),
      new LineSegment({
        totalLength: 60,
        totalWidthChange: 1.5,
        totalRotation: -Math.PI / 2 * 0.9,
      }),
      new LineSegment({
        totalLength: 80,
        totalWidthChange: 0.5,
        totalScaleChange: 0.8,
        totalRotation: Math.PI / 2 * 1.1,
      }),
      new LineSegment({
        totalLength: 20,
        totalScaleChange: 0.1,
      }),
    ],
    // Round bottom par of "K"
    [
      new LineSegment({
        totalLength: 15,
        widthAtStart: 3,
        rotationAtStart: Math.PI / 2,
      }),
      new LineSegment({
        totalLength: 100,
        rotationAtStart: -Math.PI / 2,
      }),
      new LineSegment({
        totalLength: 10,
        rotationAtStart: Math.PI / 2 * 1.05,
      }),
      new LineSegment({
        totalLength: 90,
        totalWidthChange: 2.0,
        totalRotation: Math.PI / 2 * 0.8,
      }),
      new LineSegment({
        totalLength: 80,
        totalWidthChange: 0.5,
        totalScaleChange: 0.7,
        totalRotation: -Math.PI / 2 * 0.8,
      }),
      new LineSegment({
        totalLength: 20,
        totalScaleChange: 0.1,
      }),
    ],
    // Vertical part of "K"
    [
      new LineSegment({
        totalLength: 15,
        widthAtStart: 3,
        rotationAtStart: Math.PI / 2,
      }),
      new LineSegment({
        totalLength: 195,
        rotationAtStart: -Math.PI / 2,
      }),
    ],
    // Horizontal part of "K"
    [
      new LineSegment({
        totalLength: 20,
        widthAtStart: 3,
        rotationAtStart: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 20,
        totalWidthChange: 0.3,
      }),
    ],
    // Top horizontal part of "K"
    [
      new LineSegment({
        totalLength: 15,
        widthAtStart: 3,
        rotationAtStart: Math.PI / 2,
      }),
      new LineSegment({
        totalLength: 195,
        rotationAtStart: -Math.PI / 2,
      }),
      new LineSegment({
        totalLength: 10,
        widthAtStart: 3,
        rotationAtStart: - Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 20,
        totalWidthChange: 0.3,
      }),
    ],
  ]
});

const u = () => ({
  displacement: {
    x: 150,
    y: 0,
  },
  dimensions: {
    x: 180,
    y: 0,
  },
  lines: [
    // Vertical part of "u"
    [
      new LineSegment({
        totalLength: 50,
        widthAtStart: 3,
        rotationAtStart: -Math.PI * 0.5,
        totalRotation: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 82,
      }),
      new LineSegment({
        totalLength: 50,
        widthAtStart: 5,
        totalScaleChange: 0.1,
        rotationAtStart: -Math.PI * 0.5,
      }),
    ],
   // Round part of "u"
    [
      new LineSegment({
        totalLength: 50,
        widthAtStart: 3,
        rotationAtStart: -Math.PI * 0.5,
        totalRotation: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 30,
      }),
      new LineSegment({
        totalLength: 20,
        rotationAtStart: -Math.PI,
      }),
      new LineSegment({
        totalLength: 110,
        totalRotation: Math.PI,
      }),
      new LineSegment({
        totalLength: 82,
      }),
      new LineSegment({
        totalLength: 50,
        widthAtStart: 5,
        totalScaleChange: 0.1,
        rotationAtStart: -Math.PI * 0.5,
      }),
    ],
  ]
});

const t = () => ({
  displacement: {
    x: 45,
    y: 0,
  },
  dimensions: {
    x: 70,
    y: 0,
  },
  lines: [
    // middle horizontal parts of "t"
    [
      new LineSegment({
        totalLength: 50,
        widthAtStart: 3,
        rotationAtStart: -Math.PI * 0.5,
        totalRotation: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 90,
      }),
      new LineSegment({
        totalLength: 20,
        rotationAtStart: -Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 50,
        rotationAtStart: Math.PI,
      }),
      new LineSegment({
        totalLength: 20,
        totalScaleChange: 0.1,
      }),
    ],
    // Vertical part of "t"
    [
      new LineSegment({
        totalLength: 50,
        widthAtStart: 3,
        rotationAtStart: -Math.PI * 0.5,
        totalRotation: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 130,
      }),
      new LineSegment({
        totalLength: 20,
        totalScaleChange: 0.1,
      }),
    ],
  ]
});

const l = () => ({
  displacement: {
    x: 45,
    y: 0,
  },
  dimensions: {
    x: 40,
    y: 0,
  },
  lines: [
    // Vertical part of "l"
    [
      new LineSegment({
        totalLength: 50,
        widthAtStart: 3,
        rotationAtStart: -Math.PI * 0.5,
        totalRotation: Math.PI * 0.5,
      }),
      new LineSegment({
        totalLength: 130,
      }),
      new LineSegment({
        totalLength: 20,
        totalScaleChange: 0.1,
      }),
    ],
  ]
});

const e = () => ({
  displacement: {
    x: 110,
    y: -25,
  },
  dimensions: {
    x: 140,
    y: 0,
  },
  lines: [
    // Vertical part of "l"
    [
      new LineSegment({
        totalLength: 140,
        widthAtStart: 3,
        rotationAtStart: -Math.PI * 0.75,
        totalRotation: Math.PI * 0.75,
      }),
      new LineSegment({
        totalLength: 150,
        totalRotation: Math.PI * 0.9,
      }),
      new LineSegment({
        totalLength: 20,
      }),
      new LineSegment({
        totalLength: 80,
        rotationAtStart: Math.PI * 0.6,
      }),
      new LineSegment({
        totalLength: 20,
        totalScaleChange: 0.1,
      }),
    ],
  ]
});

const v = () => ({
  displacement: {
    x: 40,
    y: 0,
  },
  dimensions: {
    x: 80,
    y: 0,
  },
  lines: [
    // Vertical parts of "v"
    [
      new LineSegment({
        totalLength: 120,
        widthAtStart: 3,
        rotationAtStart: -Math.PI * 0.1,
      }),
      new LineSegment({
        totalLength: 50,
        widthAtStart: 5,
        totalScaleChange: 0.1,
        rotationAtStart: -Math.PI * 0.4,
      }),
    ],
    [
      new LineSegment({
        totalLength: 120,
        widthAtStart: 3,
        rotationAtStart: Math.PI * 0.1,
      }),
      new LineSegment({
        totalLength: 50,
        widthAtStart: 5,
        totalScaleChange: 0.1,
        rotationAtStart: Math.PI * 0.4,
      }),
    ],
  ]
});

let cumulative_x_dimensions = 0;
const segments= [
  D(),
  a(),
  r(),
  i(),
  a(),
  K(),
  u(),
  t(),
  l(),
  u(),
  i(),
  e(),
  v(),
  a(),
].map((letter, index) => {
  // Move the letter to the right
  const positionedLetter = {
    ...letter,
    displacement: {
      x: letter.displacement.x + cumulative_x_dimensions,
      y: letter.displacement.y,
    },
  }
  cumulative_x_dimensions += letter.dimensions.x;

  return positionedLetter
});

export const DARIA_SEGMENTS = {
  segments,
  totalWidth: cumulative_x_dimensions,
  totalHeight: 195,
}
