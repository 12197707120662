import './styles.css';

import {useEffect, useMemo, useRef, useState} from "react";
import {motion, useScroll} from "framer-motion";
import {ReactComponent as PhoneSvg} from './assets/phone-screen-3.svg'
import {ReactComponent as HumanSvg} from './assets/hupres-human.svg'
import {ReactComponent as HupresLogo} from './assets/hupres-logo.svg'
import FeaturesList from "../common/FeaturesList";
import {useTranslation} from "../localization/translations";

const FEATURE_ITEM_SCROLL_OFFSET = 0.5
const HUMAN_SVG_OFFSET = 0.1

const Message = ({messages, options, isBot}) => {
  return (
    <motion.div
      initial={{scale: 0.5, opacity: 0, translateY: 50}}
      animate={{scale: 1, opacity: 1, translateY: 0}}
      transition={{duration: 0.2}}
      className={`hupres-chat-message-content ${isBot ? 'is-bot' : 'is-human'}`}>
      {messages.map((message, index) => (
        <div className="hupres-chat-message" key={index}>{message}</div>
      ))}
      {options ? (
        <div className="hupres-chat-options">
          {options.map((option, index) => (
            <div className="hupres-chat-message" key={index}>
              🔵&nbsp;{option}
            </div>
          ))}
        </div>
      ) : null}
    </motion.div>
  );
};

const HupresDemo = () => {
  const translation = useTranslation()

  const featureItems = [
    `🤖 ${translation.AI_CHATBOT}`,
    `🧠 ${translation.PSYCHOLOGICAL_TEST}`,
    `👩🏻 ${translation.PROFILES_VIEWING}`,
    `📱 ${translation.RESPONSIVE_DESIGN}`,
  ];

  const allMessages = useMemo(() => [
    {
      messages: [translation.GREETING],
      isBot: true,
    },
    {
      messages: [translation.INTRO_MESSAGE],
      isBot: true,
    },
    {
      messages: [translation.QUESTIONNAIRE_INTRO],
      isBot: true,
    },
    {
      messages: [translation.CHAT_INVITATION],
      isBot: true,
    },
    {
      messages: [translation.HEIGHT_PROMPT],
      options: [
        translation.TALLER_THAN_AVERAGE,
        translation.SHORTER_THAN_AVERAGE,
        translation.AVERAGE_HEIGHT,
      ],
      isBot: true,
    },
    {
      messages: [translation.AVERAGE_HEIGHT],
      isBot: false,
    },
    {
      messages: [translation.EYES_PROMPT],
      options: [
        translation.BROWN_EYES,
        translation.BLUE_EYES,
        translation.GREEN_EYES,
        translation.OTHER_EYES,
      ],
      isBot: true,
    },
    {
      messages: [translation.BROWN_EYES],
      isBot: false,
    },
    {
      messages: [translation.HAIR_PROMPT],
      options: [
        translation.STRAIGHT_HAIR,
        translation.WAVY_HAIR,
        translation.THICK_HAIR,
        translation.THIN_HAIR,
      ],
      isBot: true,
    },
    {
      messages: [translation.STRAIGHT_HAIR],
      isBot: false,
    },
    {
      messages: [translation.PROFILE_SUMMARY, translation.UNIQUE_STATEMENT],
      isBot: false,
    },
    {
      messages: [translation.THANKS_RESPONSE],
      isBot: false,
    },
  ], [translation]);

  const containerRef = useRef(null);
  const messageFeedRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [humanSvgVisible, setHumanSvgVisible] = useState(false);

  const {scrollYProgress} = useScroll({target: containerRef});

  useEffect(() => {
    const unsubscribeX = scrollYProgress.on('change', (scrollPosition) => {
      const scrollPortion = 1 - (FEATURE_ITEM_SCROLL_OFFSET - scrollPosition) / FEATURE_ITEM_SCROLL_OFFSET
      let numVisibleMessages = Math.ceil(scrollPortion * allMessages.length);
      setMessages(allMessages.slice(0, numVisibleMessages));

      if (scrollPosition >= HUMAN_SVG_OFFSET) {
        // Start drawing humanSvg a bit later so that SVG drawing animation is noticeable
        setHumanSvgVisible(true);
      }
    });

    return () => unsubscribeX();
  }, [allMessages, scrollYProgress]);

  return (
    <div style={{
      width: '100%',
      height: '550vh',
    }} ref={containerRef}>
      <div style={{
        position: 'sticky',
        top: '0',
        width: '100%',
        height: '100vh',
        zIndex: '1',
      }} className="hupres-container">
        <div className="hupres-logo-container">
          <a href="https://chat.hupres.com" target="_blank" rel="noreferrer" className="hupres-logo-link">
            <HupresLogo className="hupres-logo-svg"/>
          </a>
          <p>
            <a href="https://chat.hupres.com" target="_blank" rel="noreferrer"
               className="white-link">chat.hupres.com</a>
            &nbsp;{translation.ONLINE_PSYCHOLOGICAL_ASSISTANT}
          </p>
          <FeaturesList
            featureTexts={featureItems}
            scrollOffset={FEATURE_ITEM_SCROLL_OFFSET}
            scrollYProgress={scrollYProgress}
          />
        </div>
        <div className="hupres-phone-screen-container">
          <div className="hupres-phone-overlay">
            <div className="hupres-phone-before"/>
          </div>
          <PhoneSvg className="hupres-phone-screen-svg"/>
          <div className="hupres-phone-overlay">
            <div className="hupres-phone-after"/>
          </div>
        </div>
        {humanSvgVisible ? <HumanSvg className="hupres-human-svg"/> : null}
        <div className="hupres-phone-message-feed-container">
          <div
            ref={messageFeedRef}
            className="hupres-phone-message-feed"
          >
            {
              messages.map((question, index) => (
                <Message
                  key={index}
                  messages={question.messages}
                  options={question.options}
                  isBot={question.isBot}
                  initial={{scale: 0}}
                />
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
};

export default HupresDemo;
