import apiClient from "./apiClient";

const recordEvent = async (eventName) => {
  try {
    await apiClient.post('api/events', {
      eventName,
    });
  } catch (error) {
    console.error('Error recording event:', error.response?.data || error.message);
  }
};

const sendMessage = async (messageContent, locale) => {
  try {
    const response = await apiClient.post('api/messages', {
      messageContent,
      locale,
    });
    return response.data;
  } catch (error) {
    console.error('Error sending message:', error.response?.data || error.message);
    return {
      responseMessage: "Sorry, I'm having trouble connecting to the server right now. Please try again later."
    };
  }
};

const getUserMessages = async () => {
  try {
    const response = await apiClient.get(`api/messages`);
    return response.data;
  } catch (error) {
    console.error('Error getting user messages:', error.response?.data || error.message);
    return {
      messages: [],
    }
  }
};

export { recordEvent, sendMessage, getUserMessages };
