import './styles.css';

import {motion, useScroll, useTransform} from 'framer-motion';
import React, {useRef} from 'react';

import LogoImage from './assets/swishboob_logo.png';
import KeyFeaturesImage from './assets/SwishBOOM-Key-features.png';
import RatingsImage from './assets/SwishBOOM-Rating.png';
import ReferralRewardsImage from './assets/SwishBOOM-Referral-Reward.png';
import {useTranslation} from "../localization/translations";

const SwishboomFeaturesList = ({featureTexts}) => {
  return (
    <div className="features-list-container">
      <ul className="features-list">
        {featureTexts.map((item, index) => (
          <li
            className={`features-list-item visible`}
            key={index}
          >
            <h4 className="features-list-item">{item}</h4>
          </li>
        ))}
      </ul>
    </div>
  )
};

const Swishboom = () => {
  const translation = useTranslation();
  const keyFeatureItems = [
    `🔒 ${translation.FEATURE_USER_AUTH}`,
    `📅 ${translation.FEATURE_BOOK_CONSULTATION}`,
    `🗺 ${translation.FEATURE_GOOGLE_MAP}`,
  ];

  const ratingsFeatureItems = [
    `⭐ ${translation.FEATURE_RATINGS_REVIEWS}`,
    `🔒 ${translation.FEATURE_VERIFIED_NET}`,
  ];

  const referralsFeatureItems = [
    `🎁 ${translation.FEATURE_REF_REWARD}`,
    `⚙️ ${translation.FEATURE_SETTINGS}`,
  ];

  const containerRef = useRef(null);

  const {scrollYProgress} = useScroll({
    target: containerRef,
  });

  // Bite off a bit of the scroll progress to make the animation stick and the beginning and start
  const x1 = useTransform(scrollYProgress, [0.05, 0.95], ['0vw', '-200vw']);
  const x2 = useTransform(scrollYProgress, [0.05, 0.95], ['100vw', '-100vw']);
  const x3 = useTransform(scrollYProgress, [0.05, 0.95], ['200vw', '0vw']);

  return (
    <div style={{
      width: '100%',
      height: '550vh',
    }} ref={containerRef}>
      <div style={{
        position: 'sticky',
        top: '0',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
      }}>
        <div style={{
          width: '100%',
        }}>
          <div className="swishboom-page-header">
            <div className="swishboom-page-logo-container">
              <img src={LogoImage} width="360" height="100%"/>
              <p>
                {translation.SWISHBOOM_DESCRIPTION}
              </p>
            </div>
          </div>
        </div>
        <div className="swishboom-pages-container">
          <motion.div className="swishboom-page" style={{x: x1}}>
            <SwishboomFeaturesList featureTexts={keyFeatureItems}/>
            <img src={KeyFeaturesImage} width="auto" height="600px"/>
          </motion.div>
          <motion.div className="swishboom-page" style={{x: x2}}>
            <SwishboomFeaturesList featureTexts={ratingsFeatureItems}/>
            <img src={RatingsImage} width="auto" height="600px"/>
          </motion.div>
          <motion.div className="swishboom-page" style={{x: x3}}>
            <SwishboomFeaturesList featureTexts={referralsFeatureItems}/>
            <img src={ReferralRewardsImage} width="auto" height="600px"/>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Swishboom;
