export const TRANSLATIONS_EN = {
  // -- Header --
  // Label for a link that points to the user's resume
  RESUME: "Resume",
  // Label for a navigation link to the projects section
  PROJECTS: "Projects",
  // Label for a navigation link to the side projects section
  SIDE_PROJECTS: "Side Projects",
  // Text for a navigation link that initiates a chat with an AI assistant
  LETS_CHAT: "Let's Chat!",

  // -- Banner --
  // Used in the LinkedIn profile link as alt text and screen reader text, reads "Check me on LinkedIn"
  SEE_ME_ON: "Find me on",
  // Full-Stack Web Developer displayed as a profession description
  FULL_STACK_WEB_DEVELOPER: "Frontend Developer",
  // Text indicating where the user's portfolio can be checked, reads "Check this portfolio on Github"
  CHECK_THIS_PORTFOLIO_ON: "Check this portfolio on",

  // -- chat.hupres.com --
  // Used in feature list
  AI_CHATBOT: "AI chatbot",
  // Used in feature list
  PSYCHOLOGICAL_TEST: "Psychological Test",
  // Used in feature list
  PROFILES_VIEWING: "Profiles Viewing",
  // Used in feature list
  RESPONSIVE_DESIGN: "Responsive Design",
  // Describes the nature of the website, reads "chat.hupres.com is an online psychological AI assistant"
  ONLINE_PSYCHOLOGICAL_ASSISTANT: "is an online psychological AI assistant",

  // Used as an initial greeting from the bot
  GREETING: "Hello!",
  // Describes the bot's purpose
  INTRO_MESSAGE:
    "I am a psychological AI Assistant, I can help you understand yourself better.",
  // Introduction to the questionnaire
  QUESTIONNAIRE_INTRO:
    "I will ask you a few questions first, then I will give you a summary of your psychological profile.",
  // Invitation to interact more with the bot
  CHAT_INVITATION:
    "You will also be able to chat with me to ask more specific questions and recommendations for your career and overall life choices.",

  // Asks for the user's height
  HEIGHT_PROMPT: "Please tell me if you are:",
  // Option for user being taller than average
  TALLER_THAN_AVERAGE: "Taller than average",
  // Option for user being shorter than average
  SHORTER_THAN_AVERAGE: "Shorter than average",
  // Option for average height
  AVERAGE_HEIGHT: "Average height",

  // Asks for the user's eye color
  EYES_PROMPT: "Please tell me if you have:",
  // Option for brown eye color
  BROWN_EYES: "Brown eyes",
  // Option for blue eye color
  BLUE_EYES: "Blue eyes",
  // Option for green eye color
  GREEN_EYES: "Green eyes",
  // Option for other eye colors
  OTHER_EYES: "Other",

  // Asks for the user's hair type
  HAIR_PROMPT: "Please tell me if your have:",
  // Option for straight hair
  STRAIGHT_HAIR: "Straight hair",
  // Option for wavy hair
  WAVY_HAIR: "Wavy hair",
  // Option for thick hair
  THICK_HAIR: "Thick hair",
  // Option for thin hair
  THIN_HAIR: "Thin hair",

  // Introduction to profile summary
  PROFILE_SUMMARY:
    "Thank you! Now I will share with you a summary of your psychological profile.",
  // Affirmation of the user's uniqueness
  UNIQUE_STATEMENT: "Regardless of that, you are unique and special!",
  // Simple thank-you response from the user
  THANKS_RESPONSE: "Thanks!",

  // -- relatedusers.com --
  // Text displayed in the speech bubble for the first user
  SPEECH_BUBBLE_USER_1: "I am Samantha",

  // Text displayed in the speech bubble indicating usage of same email
  SPEECH_BUBBLE_SAME_EMAIL_1: "I have used the same",
  SPEECH_BUBBLE_SAME_EMAIL_2: "e-mail as Samantha",

  // Text displayed in the speech bubble indicating usage of same phone
  SPEECH_BUBBLE_SAME_PHONE_1: "I have used the same",
  SPEECH_BUBBLE_SAME_PHONE_2: "phone as Samantha",

  // Text displayed in the speech bubble indicating usage of same location
  SPEECH_BUBBLE_SAME_LOCATION_1: "I have used the same",
  SPEECH_BUBBLE_SAME_LOCATION_2: "location as Samantha",

  // Text description for relatedusers.com link, reads "relatedusers.com is a B2B anti-fraud platform that helps detect clusters of bad actors"
  RELATED_USERS_DESCRIPTION:
    "is a B2B anti-fraud platform that helps detect clusters of bad actors",

  // Feature item: Indicates the feature of secure recording in the application
  SECURE_RECORDING: "Secure Recording of Attributes",
  // Feature item: Describes the admin panel capability for exploring user data
  ADMIN_PANEL: "Admin Panel for User Exploration",
  // Feature item: Represents the version control feature in machine learning development
  VERSIONING_ML: "Versioning for Machine Learning Development",
  // Feature item: Describes the feature for exporting data for analytics purposes
  DATA_EXPORT: "Data Export for Analytics",

  // -- SwishBOOM --
  // Description of the online marketplace provided on the logo container
  SWISHBOOM_DESCRIPTION:
    "SwishBOOM was an online marketplace for families to book babysitting appointments from caregivers",
  // Feature item: User authentication functionality
  FEATURE_USER_AUTH: "User Authentication",
  // Feature item: Booking consultations
  FEATURE_BOOK_CONSULTATION: "Booking a Consultation",
  // Feature item: Integration with Google Maps
  FEATURE_GOOGLE_MAP: "Google Map Integration",
  // Feature item: Ratings and reviews system
  FEATURE_RATINGS_REVIEWS: "Rating and Reviews",
  // Feature item: Verified network of users
  FEATURE_VERIFIED_NET: "Verified Network",
  // Feature item: Referral rewarding system
  FEATURE_REF_REWARD: "Referral Rewarding System",
  // Feature item: Settings options for users
  FEATURE_SETTINGS: "Settings",

  // -- Side Projects --
  // Title of the link that leads to the online version of the project
  VIEW_ONLINE: "View Online",
  // Title for a garden equipment and supplies online store.
  GARDEN_STORE_TITLE: "Garden Store",
  // Description for a garden equipment and supplies online store.
  GARDEN_STORE_DESCRIPTION: "Online store for garden equipment and supplies.",
  // Title for an eco-friendly products online store.
  GREEN_STORE_TITLE: "Green Store",
  // Description for an eco-friendly products online store front page.
  GREEN_STORE_DESCRIPTION: "Eco-friendly products online store front page.",
  // Title for a weather forecasting application.
  WEATHER_TITLE: "Weather",
  // Description of the weather forecasting application covering weekly forecasts.
  WEATHER_DESCRIPTION: "Weather forecasting application for a week.",
  // Title for an online dictionary application.
  DICTIONARY_TITLE: "Dictionary",
  // Description of the online dictionary application.
  DICTIONARY_DESCRIPTION: "An online dictionary application.",
  // Title for a collection of recipes.
  RECIPES_TITLE: "Recipes",
  // Description of the collection of recipes.
  RECIPES_DESCRIPTION: "A collection of recipes.",
  // Title for an animated image gallery.
  FOUR_SEASONS_TITLE: "Four Seasons",
  // Description of the animated image gallery.
  FOUR_SEASONS_DESCRIPTION: "Animated image gallery.",
  // Title for a responsive image gallery.
  IMAGE_GALLERY_TITLE: "Image Gallery",
  // Description of the beautiful and responsive image gallery.
  IMAGE_GALLERY_DESCRIPTION: "A beautiful and responsive image gallery.",
  // Title for a checkout form used in e-commerce websites.
  CHECKOUT_FORM_TITLE: "Checkout Form",
  // Description of the checkout form used in e-commerce websites.
  CHECKOUT_FORM_DESCRIPTION: "A checkout form for e-commerce websites.",
  // Title for a motivational quotes generator.
  QUOTES_GENERATOR_TITLE: "Quotes Generator",
  // Description of the motivational quotes generator.
  QUOTES_GENERATOR_DESCRIPTION:
    "Generate inspiring quotes randomly to motivate your day.",
  // Title for a responsive form suitable for modern web applications.
  RESPONSIVE_FORM_TITLE: "Responsive Form",
  // Description of the responsive form suitable for modern web applications.
  RESPONSIVE_FORM_DESCRIPTION: "Responsive form for modern web applications.",
  // Title for an interactive quiz to test React knowledge.
  QUIZ_TITLE: "Quiz",
  // Description of the interactive quiz to test React knowledge.
  QUIZ_DESCRIPTION: "Interactive quiz to test your React knowledge.",
  // Title for a Twitter feed simulation.
  TWITTER_FEED_TITLE: "Twitter Feed",
  // Description of the Twitter feed simulation.
  TWITTER_FEED_DESCRIPTION: "A Twitter feed simulation.",
  // Title for a virtual drum kit.
  DRUM_KIT_TITLE: "Drum Kit",
  // Description of the virtual drum kit.
  DRUM_KIT_DESCRIPTION: "Virtual drum kit to play drums online.",
  // Title for a sequence memory game.
  SEQUENCE_GAME_TITLE: "Sequence Game",
  // Description of the sequence memory game.
  SEQUENCE_GAME_DESCRIPTION:
    "A fun sequence memory game to improve your memory.",
  // Title for a mouse animation game.
  SPARKLE_TITLE: "Sparkle",
  // Description of the mouse animation game.
  SPARKLE_DESCRIPTION: "A fun mouse animation!",
  // Title for a reflex testing game.
  AIM_TRAINING_TITLE: "Aim Training",
  // Description of the reflex testing game.
  AIM_TRAINING_DESCRIPTION: "A fun game to test your reflexes.",

  // -- Chat --
  // Introductory message from the AI
  AI_INTRO_MESSAGE:
    "Hello! I am Daria's personal AI assistant, I can answer any questions about her skills, experience or availability!",
  // Follow-up message asking how the AI can assist
  AI_HELP_PROMPT: "How can I help you today?",
  // Placeholder text in message input field
  MESSAGE_INPUT_PLACEHOLDER: "Type a message...",
  // Button caption for sending a message
  SEND_BUTTON_TEXT: "Send",

  // -- Footer --
  // Text on a button that scrolls the user back to the top of the page
  BACK_TO_TOP: "Back to Top",
};
