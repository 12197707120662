import './styles.css';

import {useEffect, useState} from "react";

// When to stop scrolling feature items on [0, 1] interval
const SCROLL_END = 0.9;

const FeaturesList = ({featureTexts, scrollOffset, scrollYProgress}) => {
  const scrollDurationPerItem = (SCROLL_END - scrollOffset) / featureTexts.length;
  const [numVisibleFeatureItems, setNumVisibleFeatureItems] = useState(0);

  useEffect(() => {
    const unsubscribeX = scrollYProgress.on('change', (scrollPosition) => {
      let numVisible = Math.ceil((scrollPosition - scrollOffset) / scrollDurationPerItem);
      setNumVisibleFeatureItems(numVisible);
    });

    return () => unsubscribeX();
  }, [scrollYProgress, scrollOffset, scrollDurationPerItem]);

  return (
    <div className="features-list-container">
      <ul className="features-list">
        {featureTexts.map((item, index) => (
          <li
            className={`features-list-item ${index < numVisibleFeatureItems ? "visible" : "hidden"}`}
            key={index}
          >
            <h4 className="features-list-item">{item}</h4>
          </li>
        ))}
      </ul>
    </div>
  )
};

export default FeaturesList;
