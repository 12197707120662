import './styles.css';
import React, {forwardRef, useContext, useEffect, useRef, useState} from 'react';
import RobotImage from './assets/robot.png';
import RobotIconImage from './assets/robot-icon.png';
import {useTranslation} from "../localization/translations";
import {getUserMessages, sendMessage} from "../../api/api";
import LocaleContext from "../localization/LocaleContext";

const INTRO_MESSAGES = [
  {
    timestamp: 1,
    messageContentKey: "AI_INTRO_MESSAGE",
    senderRole: "assistant",
  },
  {
    timestamp: 2,
    messageContentKey: "AI_HELP_PROMPT",
    senderRole: "assistant",
  },
];

const Chat = ({headerScrollRef}) => {
  const translation = useTranslation();
  const {locale} = useContext(LocaleContext);
  const messagesEndRef = useRef(null);

  const [hasSentMessage, setHasSentMessage] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // Scroll to bottom whenever new messages are added, but don't scroll on the initial load
    if (hasSentMessage) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    async function fetchMessages() {
      const existingMessages = (await getUserMessages()).messages;
      setMessages([...INTRO_MESSAGES, ...existingMessages]);
    }

    fetchMessages();
  }, []);

  const handleSend = async () => {
    const trimmedMessage = input.trim()
    if (!trimmedMessage) {
      return
    }
    setHasSentMessage(true);
    const lastTimestamp = messages[messages.length - 1].timestamp;
    const newMessage = {
      // Add 1 to the last timestamp to ensure that the new message has a unique timestamp
      timestamp: lastTimestamp + 1,
      messageContent: trimmedMessage,
      senderRole: "user"
    };
    setMessages([...messages, newMessage]);
    setInput('');

    const response = await sendMessage(trimmedMessage, locale)
    const newResponseMessage = {
      timestamp: lastTimestamp + 2,
      messageContent: response.responseMessage,
      senderRole: "assistant"
    };
    setMessages([...messages, newMessage, newResponseMessage]);
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      await handleSend();
    }
  };

  return (
    <div className="chat-window" ref={headerScrollRef}>
      <div className="chat-robot-container">
        <img src={RobotImage} width="300px" height="auto"/>
      </div>
      <div className="chat-container">
        <div className="chat-messages">
          {messages.map(message => (
            <div key={message.timestamp} className={`chat-message ${message.senderRole}`}>
              {message.senderRole === 'assistant' ? (
                <img src={RobotIconImage} className="icon"/>
              ) : null}
              <div className="text">{message.messageContent ?? translation[message.messageContentKey]}</div>
            </div>
          ))}
          <div ref={messagesEndRef}/>
        </div>
        <div className="chat-input-area">
          <input
            type="text"
            value={input}
            onChange={e => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={translation.MESSAGE_INPUT_PLACEHOLDER}
          />
          <button onClick={handleSend}>{translation.SEND_BUTTON_TEXT}</button>
        </div>
      </div>
    </div>
  );
};

export default forwardRef((props, ref) => (
  <Chat headerScrollRef={ref}/>
));
